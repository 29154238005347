/* ====== TO TOP ====== */
jQuery(document).ready(function ($) {
    var offset = 100,
            offset_opacity = 1200,
            scroll_top_duration = 700,
            $back_to_top = $('.cd-top');
    $(window).scroll(function () {
        ($(this).scrollTop() > offset) ? $back_to_top.addClass('cd-is-visible') : $back_to_top.removeClass('cd-is-visible cd-fade-out');
        if ($(this).scrollTop() > offset_opacity) {
            $back_to_top.addClass('cd-fade-out');
        }
    });
    $back_to_top.on('click', function (event) {
        event.preventDefault();
        $('body,html').animate({
            scrollTop: 0
        }, scroll_top_duration
                );
    });
});

function getYTId(url) {
    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
        return match[2];
    } else {
        return null;
    }
}

$('oembed').each(function () {
    var yt_url = $(this).attr('url')

    var videoId = getYTId(yt_url);
    if (videoId) {
        var iframeMarkup = '<div class="videoWrapper"><iframe width="560" height="315" src="//www.youtube.com/embed/'
                + videoId + '" frameborder="0" allowfullscreen></iframe></div>';

        $(this).parent().html(iframeMarkup)
    }

})


$('a').each(function () {
    var url_href = $(this).attr("href");

    if (url_href.charAt(url_href.length - 1) === '/' && url_href.length > 1) {
        $(this).attr("href", url_href.substring(0, url_href.length - 1))
    }
})


$('.static_page,#article_single').find('img[style="float:left"]').css({
    'margin-right': '10px',
    'margin-bottom': '10px'
})

$('.static_page,#article_single').find('img[style="float:right"]').css({
    'margin-left': '10px',
    'margin-bottom': '10px'
})
