jQuery(function ($) {
    $.validator.addMethod("customemail",
            function (value, element) {
                return /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.(rs|com|cc|info|live|org|io|studio|shop|biz|in|hr|website|me|co\.(in|uk|rs)|org\.(rs)|edu\.(rs)|me|org|net|edu|gov|info|срб)$/.test(value);
            },
            "Email adresa nije uneta u ispravnom formatu"
            );

    $('#contact_form').on('submit', function (e) {

    $('#contact_form').find('#btn_send').prop('disabled', true)
        grecaptcha.ready(function () {
            grecaptcha.execute($('meta[name=recaptcha_site_key]').attr("content"), {action: 'submit'}).then(function (token) {
                console.log(token)
                $('input[name=recaptcha_v3]').val(token)

                var form = $("#contact_form");
                $.ajax({
                    type: "POST",
                    url: form.attr("action"),
                    data: $("#contact_form input, #contact_form textarea").serialize(), //only input
                    success: function (response) {
                        if (response.success) {
                            $('#message-succ').html(response.message).show()
                            $('#contact_form').find("input[type=text], textarea").val("");
                        }
                    },
                    complete: function (response) {
                        $('#contact_form').find('#btn_send').prop('disabled', false)
                    }
                });

                // Add your logic to submit to your backend server here.
            });
        });

        return false;

    });
    // KONTAKT FORMA SRB
    $("#contact_form_srb").validate({
        rules: {
            fullname: {
                required: true,
                minlength: 6
            },
            email: {
                required: true,
                email: true,
                customemail: true
            },
            phone: {
                required: true
            },
            message: {
                required: true,
                minlength: 20
            }
        },
        messages: {
            fullname: {
                required: "Unesite Vaše ime i prezime",
                minlength: "Vaše ime i prezime mora imati barem 6 karaktera"
            },
            email: {
                required: "Unesite vašu email adresu",
                email: "Email adresa nije uneta u ispravnom formatu"
            },
            phone: "Unesite vaš broj mobilnog telefona",
            message: {
                required: "Unesite vašu poruku",
                minlength: "Vaša poruka mora imati najmanje 20 karaktera"
            }
        }
    });
});