var config = {
    transitionDuration: 6
};

function Timer(callback) {
    var _timerId, 
        _start, 
        _delay,
        _remaining;

    this.pause = function() {
        window.clearTimeout(_timerId);
        var spent = new Date() - _start;
        _remaining -= spent;
    };

    this.resume = function() {
        _start = new Date();
        window.clearTimeout(_timerId);
        _timerId = window.setTimeout(callback, _remaining);
    };
    
    this.resumeFull = function() {
        _start = new Date();
        window.clearTimeout(_timerId);
        _timerId = window.setTimeout(callback, _delay);
    };
    
    this.start = function(delay) {
        if (_timerId) window.clearTimeout(_timerId);
        _start = new Date();
        _delay = delay * 1000;
        _remaining = delay * 1000;
        _timerId = window.setTimeout(callback, _remaining);
    };
    
    this.stop = function() {
        if (_timerId) window.clearTimeout(_timerId);
    };
}

function Tabs() {
    this.init = function () {        
        $('.news_slider_top .nav-tabs a[data-toggle="tab"]').on('shown.bs.tab', function () {
            newsActive.init(config.transitionDuration);
        });
    };
}
var tabs = new Tabs();
tabs.init();



function NewsActive() {
    var $_elmnts;
    var _numberOfElmnts;
    var _activeClass = 'active_news';
    var _module;
    var _timer;
    var _lastDisplayed;
    var _timeBetweenIterations;
    var _categoryId;

    this.init = function (timeBetweenItereations, startIndex = 0) {
        _module = this;
        
        var newCategoryId = $('.news_slider_top > .navigation > .nav-tabs').find('[data-toggle="tab"].active').attr('href');
        if (!_categoryId || newCategoryId !== _categoryId) {
            _categoryId = newCategoryId;
            // if timer existed (category change), kill previous time process
            if (_timer) _timer.stop();
        }
        
        _timeBetweenIterations = timeBetweenItereations;
        $_elmnts = $('.news_slider_items > .tab-content > .tab-pane.active .news_sidebar_item:visible');
        _numberOfElmnts = $_elmnts.length;
        let iterationCounter = startIndex;
        var repeater = function () {
            $_elmnts.removeClass(_activeClass);
            $_elmnts.find('.circle_loader').remove();
            $($_elmnts[iterationCounter]).addClass(_activeClass);
            $($_elmnts[iterationCounter]).append('<div class="circle_loader"><div class="countdown"><svg><circle r="18" cx="20" cy="20"></circle></svg></div></div>');
            $($_elmnts[iterationCounter]).find('circle').css({
                'animation': 'countdown ' + (timeBetweenItereations + 0.05) + 's linear infinite backwards',
                '-ms-animation': 'countdown ' + (timeBetweenItereations + 0.05) + 's linear infinite backwards',
                '-moz-animation': 'countdown ' + (timeBetweenItereations + 0.05) + 's linear infinite backwards',
                '-webkit-animation': 'countdown ' + (timeBetweenItereations + 0.05) + 's linear infinite backwards',
                '-o-animation': 'countdown ' + (timeBetweenItereations + 0.05) + 's linear infinite backwards' 
            });
            $($_elmnts[iterationCounter]).tab('show');
            iterationCounter++;
            _lastDisplayed = iterationCounter;
            if (_numberOfElmnts === iterationCounter)
                iterationCounter = 0;
            if (iterationCounter >= _numberOfElmnts)
                return;
            // init & start timer
            _timer = new Timer(repeater);
            _timer.start(timeBetweenItereations);
        };
        repeater();
    };
    
    this.thumbMouseOver = function ($thumb) {
        var $newsSidebar = $thumb.parents('.news_sidebar');
        $newsSidebar.addClass('paused');
        $thumb.tab('show');
        $thumb.parents('.news_sidebar').find('[data-toggle="tab"]').removeClass(_activeClass);
        var index = $thumb.data('news-index');
        if (_lastDisplayed !== index) {
            $thumb.remove('.circle_loader');
            $thumb.append('<div class="circle_loader"><div class="countdown"><svg><circle r="18" cx="20" cy="20"></circle></svg></div></div>');
        }
        $thumb.find('circle').css({
            '-webkit-animation-play-state': 'paused',
            '-moz-animation-play-state': 'paused',
            '-o-animation-play-state': 'paused',
            '-ms-animation-play-state': 'paused',
            'animation-play-state': 'paused'
        });
        $thumb.addClass(_activeClass);
        _timer.pause();
    };
    
    this.thumbMouseOut = function ($thumb) {
        var $newsSidebar = $thumb.parents('.news_sidebar');
        $newsSidebar.removeClass('paused');
        var index = $thumb.data('news-index');
        if (_lastDisplayed !== index) {
            _module.init(_timeBetweenIterations, index - 1);
        } else {
            _timer.resume();
        }
        $thumb.find('circle').css({
            '-webkit-animation-play-state': 'running',
            '-moz-animation-play-state': 'running',
            '-ms-animation-play-state': 'running',
            '-o-animation-play-state': 'running',
            'animation-play-state': 'running'
        });
    };
    
    this.getLastDisplayed = function () {
        return _lastDisplayed;
    };
}

var newsActive = new NewsActive();
newsActive.init(config.transitionDuration); // in seconds
$(document).on('mouseenter', '.news_slider_items > .tab-content > .tab-pane.active .news_sidebar .news_sidebar_item', function (e) {
    e.preventDefault();
    $('.news_slider_items > .tab-content > .tab-pane.active .news_box .tab-pane').removeClass('active show');
    var tabContentSelector = $(this).attr('href');
    $(this).tab('show');
    $(tabContentSelector).addClass('active show');
    newsActive.thumbMouseOver($(this));
});

$(document).on('mouseleave', '.news_slider_items > .tab-content > .tab-pane.active .news_sidebar .news_sidebar_item', function () {
    newsActive.thumbMouseOut($(this));
});